<template>
  <div>
    <TooltipButton>
      <template v-slot:content>
        <BaseButton
          variant="salmon"
          :size="buttonSize"
          @click="modalOpen = true"
        >
          <template slot="iconMiddle">
            <Icon
              view-box="0 0 24 24"
              class="w-5 h-5"
            >
              <Unarchive />
            </Icon>
          </template>
        </BaseButton>
      </template>
      <template v-slot:tooltip>
        Unarchive Assessment
      </template>
    </TooltipButton>

    <Modal
      :open="modalOpen"
      @close="attemptClose()"
    >
      <div class="bg-white border-b-2">
        <div class="flex justify-between px-6 pb-6">
          <h3 class="text-lg font-medium">
            Unarchive assessment
          </h3>
          <button
            v-if="!isProcessing"
            class="text-gray-500 focus:outline-none"
            @click="modalOpen = false"
          >
            <Icon
              view-box="0 0 24 24"
              class="w-5 h-5 transform rotate-45"
            >
              <Plus />
            </Icon>
          </button>
        </div>
      </div>
      <div
        v-if="!isProcessing"
        class="m-6"
      >
        <p>Are you sure you want to unarchive {{ job.name }}?</p>

        <p class="my-6">
          This will add to your current assessments.
        </p>

        <div class="flex justify-between my-6">
          <BaseButton
            variant="gray"
            @click="modalOpen = false"
          >
            No, cancel
          </BaseButton>
          <BaseButton
            @click="openJob()"
          >
            Yes, unarchive
          </BaseButton>
        </div>
      </div>
      <div
        v-else
        class="my-16"
      >
        <Loader />
      </div>
    </Modal>
  </div>
</template>

<script>
import Icon from '@components/Icons/Icon'
import Loader from '@components/Loader'
import Modal from '@components/Modal'
import Plus from '@components/Icons/Plus'
import Unarchive from '@components/Icons/Unarchive'
import TooltipButton from '@components/TooltipButton'

export default {
  components: {
    Icon,
    Loader,
    Modal,
    Plus,
    Unarchive,
    TooltipButton
  },

  props: {
    job: {
      type: Object,
      default: null
    },
    buttonSize: {
      type: String,
      default: 'small'
    }
  },

  data() {
    return {
      isProcessing: false,
      modalOpen: false
    }
  },

  methods: {
    /**
     * Attempt to close the modal
     */
    attemptClose() {
      if (this.isProcessing) {
        return // Cannot close while processing
      }
      this.modalOpen = false
    },

    openJob() {
      if (this.isProcessing) {
        return
      }
      this.isProcessing = true

      this.$store.dispatch('jobs/open', this.job.uuid)
        .then(() => {
          this.$emit('close')
        })
        .catch(error => {
          this.isProcessing = false
          this.errorMessage = error.message
        })
    }
  }
}
</script>
