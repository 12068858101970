<template>
  <div>
    <TooltipButton @click="handleClick">
      <template v-slot:content>
        <BaseButton
          variant="salmon"
        >
          <template slot="iconMiddle">
            <Icon
              view-box="0 0 24 24"
              class="w-5 h-5"
              stroke="currentColor"
              stroke-width="2"
            >
              <Edit />
            </Icon>
          </template>
        </BaseButton>
      </template>
      <template v-slot:tooltip>
        Edit Assessment
      </template>
    </TooltipButton>

    <Modal
      :open="modalOpen"
      size="max-w-2xl"
      @close="modalOpen = false"
    >
      <div class="bg-white border-b-2">
        <div class="flex justify-between px-6 pb-6">
          <h3 class="text-lg font-medium">
            Edit assessment
          </h3>
          <button
            class="text-gray-500 focus:outline-none"
            @click="modalOpen = false"
          >
            <Icon
              view-box="0 0 24 24"
              class="w-5 h-5 transform rotate-45"
            >
              <Plus />
            </Icon>
          </button>
        </div>
      </div>

      <EditJobForm
        :job="job"
        @close="updateJob()"
      />
    </Modal>
  </div>
</template>

<script>
import Modal from '@components/Modal'
import EditJobForm from '@components/Jobs/EditJobForm'
import Icon from '@components/Icons/Icon'
import Plus from '@components/Icons/Plus'
import Edit from '@components/Icons/Edit'
import TooltipButton from '@components/TooltipButton'

export default {
  components: {
    Modal,
    EditJobForm,
    Icon,
    Plus,
    Edit,
    TooltipButton
  },

  props: {
    job: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      modalOpen: false
    }
  },

  methods: {
    updateJob() {
      this.modalOpen = false
      this.$emit('jobUpdated')
    },

    handleClick() {
      if (this.$usesFeature('classic-jobs')) {
        this.modalOpen = true
      }

      if (this.$usesFeature('candidate-wizard')) {
        this.$router.push({
          name: 'job-edit',
          params: { id: this.job.uuid }
        })
      }
    }
  }
}
</script>
