<template>
  <div v-if="state === states.READY">
    <BasePageHeader>
      <div>
        <BasePageBreadcrumbs :breadcrumbs="breadcrumbs"></BasePageBreadcrumbs>
        <BasePageHeading>{{ assessment.name }}</BasePageHeading>
      </div>

      <BasePageActions>
        <EditJobButton
          :job="assessment"
          @jobUpdated="jobUpdated()"
        >
          <span>Edit Assessment</span>
        </EditJobButton>
        <ArchiveAssessmentButton
          v-if="!assessment.closedAt"
          :job="assessment"
          button-size="standard"
          @close="jobDeleted()"
        />
        <UnarchiveAssessmentButton
          v-else-if="assessment.closedAt"
          :job="assessment"
        />
        <TooltipButton>
          <template v-slot:content>
            <BaseButton
              variant="salmon"
              :disabled="!canEditExams"
              :to="{ name: 'client-assessments-exams', params: { id: assessment.uuid } }"
            >
              <template slot="iconMiddle">
                <Icon
                  view-box="0 0 24 24"
                  class="w-5 h-5"
                >
                  <ClipboardList />
                </Icon>
              </template>
            </BaseButton>
          </template>
          <template v-slot:tooltip>
            <span v-if="canEditExams">Edit Tests</span>
            <span v-else>You can’t edit tests if the assessment has already been sent</span>
          </template>
        </TooltipButton>

        <OpenSignUpJobButton
          :job="assessment"
        />
      </BasePageActions>
    </BasePageHeader>

    <BaseTabBar>
      <BaseTabs>
        <BaseTab
          :to="{ name: 'client-assessments-show', params: { id: assessment.uuid } }"
          :active="true"
        >
          Candidates
        </BaseTab>
        <BaseTab
          v-if="(assessment.attemptsAverageScorePercentage || assessment.attemptsAverageMinutesCompleted)"
          :to="{ name: 'client-assessments-analytics', params: { id: assessment.uuid } }"
        >
          Analytics
        </BaseTab>
      </BaseTabs>
    </BaseTabBar>

    <BaseWrapper v-if="candidates.length === 0">
      <AssessmentEmpty>
        <div v-if="assessment.exams && assessment.exams.length > 0">
          <p class="mb-6 text-gray-600 text-md">
            You can edit the tests in this assessment up until the first
            candidate takes a test. Once you’re ready, share this link with
            your candidates, or send an invitation:
          </p>
          <div class="mt-10">
            <OpenSignUpJobButton
              :job="assessment"
            />
          </div>
        </div>
        <div v-if="!assessment.exams || assessment.exams.length === 0">
          <p class="mb-6 text-gray-600 text-md">
            You can edit the tests in this assessment up until the first
            candidate takes a test. Add some tests now before you invited
            your first candidate:
          </p>
          <div class="mt-10">
            <BaseButton
              variant="salmon"
              :disabled="!canEditExams"
              :to="{ name: 'client-assessments-exams', params: { id: assessment.uuid } }"
            >
              Edit Tests
            </BaseButton>
          </div>
        </div>
      </AssessmentEmpty>
    </BaseWrapper>
    <BaseWrapper v-else-if="candidates.length > 0">
      <BaseTable>
        <BaseTHead>
          <tr>
            <BaseTHeadTh>
              Name
            </BaseTHeadTh>
            <BaseTHeadTh
              :colspan="(assessment.exams.length > 1) ? assessment.exams.length : 1"
              align="right"
            >
              Scores
            </BaseTHeadTh>
            <BaseTHeadTh align="right">
              Added
            </BaseTHeadTh>
            <BaseTHeadTh align="right">
              Status
            </BaseTHeadTh>
            <BaseTHeadTh
              align="right"
              class="sr-only"
            >
              Actions
            </BaseTHeadTh>
          </tr>
        </BaseTHead>
        <BaseTBody>
          <tr
            v-for="candidate in candidates"
            :key="candidate.uuid"
          >
            <BaseTBodyTh>
              <router-link :to="{ name: 'client-assessments-candidate', params: { id: assessment.uuid, candidate: candidate.uuid } }">
                {{ candidate.firstName }}
                {{ candidate.surname }}
              </router-link>
            </BaseTBodyTh>
            <template v-if="assessment.exams.length">
              <BaseTBodyTd
                v-for="exam in assessment.exams"
                :key="`${candidate.uuid}-${exam.slug}`"
              >
                <div class="flex-1 text-right">
                  <span class="inline-flex items-center space-x-1">
                    <ExamIcon
                      :icon="exam.icon"
                      class="w-3 h-3"
                    />
                    <span>{{ findScoreForCandidateExam(candidate, exam) }}</span>
                  </span>
                </div>
              </BaseTBodyTd>
            </template>
            <template v-else>
              <BaseTBodyTd align="right">
                ...
              </BaseTBodyTd>
            </template>

            <BaseTBodyTd align="right">
              {{ formatDate(candidate.createdAt) }}
            </BaseTBodyTd>
            <BaseTBodyTd align="right">
              <span v-if="candidate.attempts.length < assessment.exams.length">
                In progress
              </span>
              <span v-else-if="candidate.attempts.length >= assessment.exams.length">
                Completed
              </span>
            </BaseTBodyTd>
            <BaseTBodyTd align="right">
              <div class="flex justify-end">
                <TooltipButton>
                  <template v-slot:content>
                    <BaseButton
                      variant="salmon"
                      size="small"
                      :to="{ name: 'client-assessments-candidate', params: { id: assessment.uuid, candidate: candidate.uuid } }"
                    >
                      <template slot="iconMiddle">
                        <Icon
                          view-box="0 0 24 24"
                          class="w-5 h-5"
                        >
                          <Eye />
                        </Icon>
                      </template>
                    </BaseButton>
                  </template>
                  <template v-slot:tooltip>
                    View results
                  </template>
                </TooltipButton>
              </div>
            </BaseTBodyTd>
          </tr>
        </BaseTBody>
      </BaseTable>

      <ul class="flex m-4 space-x-2 text-sm text-gray-600">
        <li
          v-for="exam in assessment.exams"
          :key="exam.slug"
          class="inline-flex items-center space-x-1"
        >
          <ExamIcon
            :icon="exam.icon"
            class="w-3 h-3"
          />
          <span>{{ exam.name }}</span>
        </li>
      </ul>

      <Pagination
        :pagination="meta"
        @goToPage="changePage($event)"
      />
    </BaseWrapper>
  </div>
  <div v-else-if="state === states.LOADING">
    <BasePageHeader>
      <div>
        <BasePageBreadcrumbs></BasePageBreadcrumbs>
        <BasePageHeading></BasePageHeading>
      </div>
    </BasePageHeader>
    <Loader />
  </div>
  <div v-else-if="state === states.ERROR">
    <BaseErrorBlock />
  </div>
</template>

<script>
// Components
import ArchiveAssessmentButton from '@components/ClientAssessments/ArchiveAssessmentButton'
import AssessmentEmpty from '@components/ClientAssessments/AssessmentEmpty'
import EditJobButton from '@components/Jobs/EditJobButton'
import Loader from '@components/Loader'
import OpenSignUpJobButton from '@components/Jobs/OpenSignUpJobButton'
import UnarchiveAssessmentButton from '@components/ClientAssessments/UnarchiveAssessmentButton'
import ExamIcon from '@components/Icons/Exams/ExamIcon'
import Pagination from '@components/TUI/Pagination'
import TooltipButton from '@components/TooltipButton'
import ClipboardList from '@components/Icons/ClipboardList'
import Icon from '@components/Icons/Icon'
import Eye from '@components/Icons/Eye'

// Utilities
import jobCandidatesApi from '@api/jobCandidates'
import states from '@api/states'
import { formatDate } from '@utils/formatDate'
import { mapGetters } from 'vuex'

export default {
  components: {
    ArchiveAssessmentButton,
    AssessmentEmpty,
    EditJobButton,
    Loader,
    OpenSignUpJobButton,
    UnarchiveAssessmentButton,
    ExamIcon,
    Pagination,
    TooltipButton,
    ClipboardList,
    Icon,
    Eye
  },

  data() {
    return {
      states,
      formatDate,
      pageNumber: 1,

      error: null,
      candidates: null,
      meta: null,
      assessment: null
    }
  },

  page() {
    return {
      title: this.assessmentName
    }
  },

  computed: {
    ...mapGetters({
      organisationName: 'organisations/name'
    }),

    /**
     * @return {Array}
     */
    breadcrumbs() {
      return [
        {
          name: this.organisationName
        },
        {
          name: 'Assessments',
          to: 'client-assessments-list'
        }
      ]
    },

    /**
     * @return {string}
     */
    state() {
      if (this.error) {
        return states.ERROR
      }
      if (!this.candidates) {
        return states.LOADING
      }

      return states.READY
    },

    /**
     * @return {string}
     */
    assessmentName() {
      if (!this.assessment) {
        return ''
      }
      return this.assessment.name
    },

    /**
     * @return {Boolean}
     */
    canEditExams() {
      if (!this.candidates) {
        return false
      }
      return this.candidates.length === 0
    }
  },

  created() {
    this.fetchAssessmentCandidates()
  },

  methods: {
    /**
     * Fetch the job profile based on the job id
     */
    fetchAssessmentCandidates() {
      this.candidates = null
      this.meta = null
      this.assessment = null

      return jobCandidatesApi.index(this.$route.params.id, this.pageNumber)
        .then(response => {
          this.candidates = response.data
          this.meta = response.meta
          this.assessment = response.job
        })
        .catch(error => {
          this.error = error
          throw error
        })
    },

    /**
     * Change page
     *
     * @param {Number} pageNumber
     */
    changePage(pageNumber) {
      this.pageNumber = pageNumber

      this.fetchAssessmentCandidates()
    },

    /**
     * A job has been deleted
     */
    jobDeleted() {
      this.$router.push({ name: 'jobs' })
    },

    /**
     * A job has been updated
     */
    jobUpdated() {
      this.fetchAssessmentCandidates()
    },

    /**
     * Returns a string of the ‘score’ (most likely a percentage) for the given
     * exam and candidate
     *
     * @param {Object} candidate
     * @param {Object} exam
     * @return {string}
     */
    findScoreForCandidateExam(candidate, exam) {
      if (!candidate.attempts || candidate.attempts.length === 0) {
        return '–'
      }

      const attempt = candidate.attempts.find(attempt => attempt.examSlug === exam.slug)

      if (attempt.scorePercentage === null) {
        return '–'
      }

      return `${attempt.scorePercentage}%`
    }
  }
}
</script>
