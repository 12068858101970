<template>
  <div>
    <TooltipButton>
      <template v-slot:content>
        <BaseButton
          variant="salmon"
          :size="buttonSize"
          @click="modalOpen = true"
        >
          <template slot="iconMiddle">
            <Icon
              view-box="0 0 24 24"
              class="w-5 h-5"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
            >
              <Archive fill="none" />
            </Icon>
          </template>
        </BaseButton>
      </template>
      <template v-slot:tooltip>
        Archive Assessment
      </template>
    </TooltipButton>

    <Modal
      :open="modalOpen"
      @close="attemptClose()"
    >
      <div class="bg-white border-b-2">
        <div class="flex justify-between px-6 pb-6">
          <h3 class="text-lg font-medium">
            Archive assessment
          </h3>
          <button
            v-if="!isProcessing"
            class="text-gray-500 focus:outline-none"
            @click="modalOpen = false"
          >
            <Icon
              view-box="0 0 24 24"
              class="w-5 h-5 transform rotate-45"
            >
              <Plus />
            </Icon>
          </button>
        </div>
      </div>
      <div
        v-if="!isProcessing"
        class="m-6"
      >
        <p>Are you sure you want to archive {{ job.name }}?</p>

        <p class="my-6">
          You’ll be able to view the assessment, but no new candidates can be added.
        </p>

        <div class="flex justify-between my-6">
          <BaseButton
            variant="gray"
            @click="modalOpen = false"
          >
            No, Cancel
          </BaseButton>
          <BaseButton
            variant="danger"
            @click="closeJob()"
          >
            Yes, Archive
          </BaseButton>
        </div>
      </div>
      <div
        v-else
        class="my-16"
      >
        <Loader />
      </div>
    </Modal>
  </div>
</template>

<script>
import Icon from '@components/Icons/Icon'
import Loader from '@components/Loader'
import Modal from '@components/Modal'
import Archive from '@components/Icons/Archive'
import Plus from '@components/Icons/Plus'
import TooltipButton from '@components/TooltipButton'

import Vue from 'vue'

import VueTippy, { TippyComponent } from 'vue-tippy'

Vue.use(VueTippy)
Vue.component('tippy', TippyComponent)

export default {
  components: {
    Icon,
    Loader,
    Modal,
    Plus,
    Archive,
    TooltipButton
  },

  props: {
    job: {
      type: Object,
      default: null
    },
    buttonSize: {
      type: String,
      default: 'small'
    }
  },

  data() {
    return {
      isProcessing: false,
      modalOpen: false
    }
  },

  methods: {
    /**
     * Attempt to close the modal
     */
    attemptClose() {
      if (this.isProcessing) {
        return // Cannot close while processing
      }
      this.modalOpen = false
    },

    closeJob() {
      if (this.isProcessing) {
        return
      }
      this.isProcessing = true

      this.$store.dispatch('jobs/close', this.job.uuid)
        .then(() => {
          this.attemptClose()
        })
        .catch(error => {
          this.isProcessing = false
          this.errorMessage = error.message
        })
    }
  }
}
</script>
